/* global i18n, Redactor */

import { Controller } from '@hotwired/stimulus';
import { getRedactor } from '@redactor/helpers';

export default class extends Controller {
  static targets = ['editor', 'toolbar', 'formatButton'];

  static values = {
    codeEditorQuestionId: { type: String },
    collapsed: { type: Boolean, default: false },
    excludedButtons: { type: Array },
    excludedPlugins: { type: Array },
    extended: { type: Boolean, default: false },
    fileManagerUrl: { type: String },
    focusEnd: { type: Boolean, default: false },
    handle: { type: String },
    imageManagerUrl: { type: String },
    includedButtons: { type: Array },
    includedPlugins: { type: Array },
    submitOnMetaEnter: { type: Boolean, default: true }
  };

  connect() {
    [this.textArea] = this.element.children;

    let lang = i18n.locale;
    if (lang === 'nl_vo') lang = 'nl';

    const options = {
      callbacks: {
        changed: this.#onChanged.bind(this),
        enter: this.#onEnter.bind(this),
        pasting: this.#onPasting.bind(this),
        syncing: this.#onSyncing.bind(this)
      },
      grammarly: true,
      spellcheck: true,
      lang
    };

    if (this.extendedValue) {
      options.buttons = ['bold', 'italic', 'underline', 'link', 'ol', 'ul', 'sub', 'sup', 'undo', 'redo'];
      options.plugins = ['color-plugin', 'heading', 'upload', 'blockquote', 'table', 'alignment',
        'specialchars', 'pre', 'equation'];
    } else {
      options.buttons = ['bold', 'italic', 'underline', 'link', 'ol', 'ul', 'undo', 'redo'];
      options.plugins = ['color-plugin', 'heading', 'upload', 'blockquote'];
    }

    options.plugins = options.plugins.concat('list', 'link-plugin', 'history', 'html');

    if (this.includedButtonsValue) {
      options.buttons = [...new Set([...options.buttons, ...this.includedButtonsValue])];
    }

    if (this.excludedButtonsValue) {
      options.buttons = options.buttons.filter((button) => !this.excludedButtonsValue.includes(button));
    }

    if (this.includedPluginsValue) {
      options.plugins = [...new Set([...options.plugins, ...this.includedPluginsValue])];
    }

    if (this.excludedPluginsValue) {
      options.plugins = options.plugins.filter((plugin) => !this.excludedPluginsValue.includes(plugin));
    }

    if (this.handleValue) options.plugins.push('handle');
    if (this.fileManagerUrlValue) options.plugins.push('filemanager');
    if (this.imageManagerUrlValue) options.plugins.push('imagemanager');
    if (this.codeEditorQuestionIdValue) options.plugins.push('variable');

    if (options.plugins.includes('shortcut')) options.plugins.push('shortcutAutocomplete');

    getRedactor().then((Redactor) => {
      if (this.redactor) {
        this.redactor.start();
      } else {
        this.redactor = Redactor(this.textArea, options);

        if (this.focusEndValue) {
          setTimeout(() => this.redactor.editor.endFocus(), 100);
        }
      }

      if (this.textArea.hasAttribute('disabled')) {
        this.disableEditor();
      }
    });
  }

  editorTargetConnected() {
    if (this.collapsedValue) this.#relocateButtons();
  }

  toolbarTargetConnected() {
    if (this.collapsedValue) this.toolbarTarget.classList.add('d-none');
  }

  disableEditor() {
    this.element.classList.add('redactor--disabled');
    this.redactor.enableReadOnly();
  }

  toggleFormat() {
    this.toolbarTarget.classList.toggle('d-none');
    this.redactor.selection.restore();
  }

  #onChanged() {
    const event = new CustomEvent('redactor:changed', { bubbles: true });
    this.element.dispatchEvent(event);
  }

  #onEnter(e) {
    if (e.metaKey) {
      if (this.submitOnMetaEnterValue) {
        const form = this.element.closest('form');
        if (form) form.requestSubmit();
      }

      const event = new CustomEvent('redactorEventEnter', { detail: { event: e, redactor: this.redactor } });
      document.dispatchEvent(event);

      return false;
    }

    return true;
  }

  #onPasting(cleanedHtml) {
    const html = cleanedHtml.replace(/<\/?p[^>]*>/gi, (match) => (match.includes('</') ? '</div>' : '<div>'));
    return html;
  }

  #onSyncing(cleanedHtml) {
    if (this.redactor.editor.isEmpty()) return '';

    return Redactor.options.callbacks.syncing(cleanedHtml);
  }

  #relocateButtons() {
    const wrapper = document.createElement('div');
    wrapper.classList.add('redactor-editor-wrapper');

    const formatButton = document.createElement('div');
    formatButton.textContent = 'text_format';
    formatButton.classList.add('redactor__inline_button', 'button-icon', 'button-icon--small', 'my-2');
    formatButton.setAttribute('aria-label', i18n.t('js.general.formatting'));
    formatButton.setAttribute('data-controller', 'tooltip');
    formatButton.setAttribute('data-redactor-target', 'formatButton');
    formatButton.setAttribute('data-action', 'click->redactor#toggleFormat');

    this.editorTarget.classList.add('flex-grow');
    this.editorTarget.parentNode.insertBefore(wrapper, this.editorTarget);
    wrapper.appendChild(this.editorTarget);
    wrapper.appendChild(formatButton);

    const upload = this.element.querySelector('.re-upload');
    if (upload) {
      upload.classList.add('redactor__inline_button', 'my-2');
      wrapper.appendChild(upload);
    }

    const record = this.element.querySelector('.re-record');
    if (record) {
      record.classList.add('redactor__inline_button', 'my-2');
      wrapper.appendChild(record);
    }
  }

  disconnect() {
    if (this.redactor) this.redactor.stop();
  }
}
